import { Skeleton } from '@lanekassen/ui';
import { createContext, useContext } from 'react';
import { ForsideEpiContent } from '../epitekster/types';
import NoeGikkGalt from '../components/NoeGikkGalt';
import { useTeksterQuery } from '../hooks/queries';

const ForsideteksterContext = createContext<ForsideEpiContent | null>(null);

export function useForsideteksterContext() {
  return useContext(ForsideteksterContext)!;
}

export function ForsideteksterContextProvider({ children }: any) {
  const { data: forsideTekster, isLoading, isError } = useTeksterQuery<ForsideEpiContent>('Forside');

  if (isLoading) {
    return <Skeleton width="100%" height="200px" />;
  }
  if (isError || !forsideTekster) {
    return <NoeGikkGalt />;
  }

  return <ForsideteksterContext.Provider value={forsideTekster}>{children}</ForsideteksterContext.Provider>;
}
