import { useBrowserLocation } from 'wouter/use-browser-location';

export type SupportedLocales = 'en-US' | 'nb-NO' | 'nn-NO';
export function useCurrentLanguage() {
  const [location] = useBrowserLocation();
  const currentLang = extractLocaleCodeFromUrl(location);
  return { localeCode: localeCodeToLocaleCode(currentLang) };
}

export function extractLocaleCodeFromUrl(location: string) {
  return location.toLowerCase().match(/(en-us|nb-no|nn-no)/)?.[1];
}

export function localeCodeToLocaleCode(localeCode?: string): SupportedLocales {
  switch (localeCode?.toLowerCase()) {
    case 'nn-no':
      return 'nn-NO';
    case 'en-us':
      return 'en-US';
    default:
      return 'nb-NO';
  }
}
