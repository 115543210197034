import { Button, Stack, Dialog, Heading } from '@lanekassen/ui';
import React from 'react';
import { useCurrentLanguage } from '../hooks/currentLangauge';

const tekster = {
  'en-US': {
    overskrift: 'Something went wrong',
    ingress: 'Something went wrong. Please try again later.',
    tilforsiden: 'To front page',
    provPaNytt: 'Try again',
  },
  'nb-NO': {
    overskrift: 'Noe gikk galt',
    ingress: 'Noe gikk galt. Vennligst prøv igjen senere.',
    tilforsiden: 'Til forsiden',
    provPaNytt: 'Prøv på nytt',
  },
  'nn-NO': {
    overskrift: 'Noke gjekk gale',
    ingress: 'Noko gjekk gale. Ver venleg og prøv om att seinare.',
    tilforsiden: 'Til forsida',
    provPaNytt: 'Prøv på nytt',
  },
} as const;

function NoeGikkGalt() {
  const { localeCode } = useCurrentLanguage();
  const returnUrl = encodeURIComponent(`${window.location.pathname}`);
  const provPaNyttLenke = `/du/logger_inn?ReturnUrl=${returnUrl}&culture=${localeCode}`;
  const { overskrift, ingress, provPaNytt, tilforsiden } = tekster[localeCode];
  return (
    <Dialog isOpen>
      <Dialog.Header>
        <Heading level={1} size="lg">
          {overskrift}
        </Heading>
      </Dialog.Header>
      <Dialog.Content>{ingress}</Dialog.Content>
      <Dialog.Footer>
        <Stack spacing={2} direction="row" autoFocus>
          <Button variant="solid" href={provPaNyttLenke}>
            {provPaNytt}
          </Button>
          <Button>{tilforsiden}</Button>
        </Stack>
      </Dialog.Footer>
    </Dialog>
  );
}

export default NoeGikkGalt;
