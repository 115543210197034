import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { CssBaseline, lanekassenTheme, Page, ThemeProvider } from '@lanekassen/ui';
import dompurify from 'dompurify';
import ForeldresigneringFooter from './components/ForeldresigneringFooter';
import ForeldresigneringHeader from './components/ForeldresigneringHeader';
import { ForsideteksterContextProvider } from './context/forsideteksterContext';

import Forside from './routes/Forside';
import { LoginContextProvider } from './context/loginContext';

// TODO: Vi bør dele denne logikken med DineSider/LKNO/FellesInngang også
dompurify.addHook('afterSanitizeAttributes', (node) => {
  // sett alle elementer som har target til target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener');
  }
});

function MainContent() {
  return (
    <>
      <ForeldresigneringHeader />
      <main id="main">
        <Forside />
      </main>
      <ForeldresigneringFooter />
    </>
  );
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Number.POSITIVE_INFINITY,
    },
  },
});

function QueryProvider() {
  return (
    <QueryClientProvider client={queryClient}>
      <LoginContextProvider>
        <ForsideteksterContextProvider>
          <MainContent />
        </ForsideteksterContextProvider>
      </LoginContextProvider>
    </QueryClientProvider>
  );
}

function App() {
  return (
    <ThemeProvider theme={lanekassenTheme}>
      <CssBaseline />
      <Page>
        <QueryProvider />
      </Page>
    </ThemeProvider>
  );
}

export default App;
