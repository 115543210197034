import { Box, Header } from '@lanekassen/ui';
import { useBrowserLocation } from 'wouter/use-browser-location';
import purify from 'dompurify';
import { SupportedLocales, useCurrentLanguage } from '../hooks/currentLangauge';
import { useLoginContext } from '../context/loginContext';
import { useForsideteksterContext } from '../context/forsideteksterContext';

function ForeldresigneringHeader() {
  const [location, setLocation] = useBrowserLocation();
  const user = useLoginContext();
  const { localeCode } = useCurrentLanguage();
  const forsideTekster = useForsideteksterContext();
  function navigateToLanguage(locale: { label: string; code: string }) {
    // TODO: Ta høyde for forskjellige paths for forskjellige sprak?
    const afterLanguagePath = location
      .toLowerCase()
      ?.split(/(en-us|nb-no|nn-no)/)
      .at(-1);
    setLocation(`/${locale.code}${afterLanguagePath}`);
  }
  document.documentElement.lang = localeCode;
  const title = document.head.querySelector('title');
  if (title && forsideTekster?.sidetittel)
    title.textContent = purify.sanitize(forsideTekster.sidetittel, { ALLOWED_TAGS: [] });
  let name: string | null = null;
  if (user.isAuthenticated) {
    if (user.fornavn && user.etternavn) name = `${user.fornavn} ${user.etternavn}`;
    else if (user.kortnavn) name = user.kortnavn;
  }
  if (name === null) name = '';
  // TODO: Må endre språk på header i designsystemet?
  return (
    <Box sx={{ mt: { sx: 0, sm: 2 } }}>
      <ImpersonertAv impersonertAv={user.impersonertAv} name={name} />
      <Header
        logotext={`Foreldresignering${user.impersonertAv ? ' Pluss' : ''}`}
        logoUri={`/${localeCode}/foreldresignering`}
        user={{
          isAuthenticated: user.isAuthenticated,
          name,
        }}
        loginUri="/du/logger_ut"
        logoutUri="/du/logger_ut"
        onChangeLocale={(_, newLocale) => navigateToLanguage(newLocale)}
        initialLocale={{ code: localeCode, label: getLocaleLabel(localeCode) }}
        skipLinkElementId="#main"
        skipLinkLabel={forsideTekster.hoppTilInnhold}
        logoutLabel={forsideTekster.loggUtLenkeTekst}
        menuLabel={forsideTekster.headerMenyKnapp}
        closeMenuLabel={forsideTekster.headerMenyLukkKnapp}
      />
    </Box>
  );
}

function ImpersonertAv({ impersonertAv, name }: { impersonertAv: string | null; name: string }) {
  if (impersonertAv)
    return (
      <div style={{ textAlign: 'end' }}>
        <strong>{impersonertAv}</strong>
        {name && (
          <>
            {' '}
            på vegne av <strong>{name}</strong>
          </>
        )}
      </div>
    );
  return null;
}

function getLocaleLabel(localeCode: SupportedLocales): string {
  switch (localeCode) {
    case 'en-US':
      return 'English';
    case 'nb-NO':
      return 'Bokmål';
    case 'nn-NO':
      return 'Nynorsk';
    default:
      return 'Ukjent';
  }
}

export default ForeldresigneringHeader;
