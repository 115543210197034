import ReactDOM from 'react-dom/client';
import { StrictMode } from 'react';
import App from './App';

import '@fontsource/roboto/400.css';
import '@fontsource/roboto/400-italic.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/700-italic.css';
import '@fontsource/rubik/400.css';
import '@fontsource/rubik/500.css';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
