import { Skeleton } from '@lanekassen/ui';
import { createContext, useContext } from 'react';
import { startSjekkForInaktivitetUtenDynamicImport } from '@modulis/inaktivitet';
import { extractLocaleCodeFromUrl } from '../hooks/currentLangauge';
import NoeGikkGalt from '../components/NoeGikkGalt';
import { UserType, useDUQuery } from '../hooks/queries';

const LoginContext = createContext<UserType | null>(null);

export function useLoginContext() {
  return useContext(LoginContext)!;
}

export function LoginContextProvider({ children }: any) {
  const { data: user, isLoading, isError } = useDUQuery();

  if (isLoading) {
    return <Skeleton width="100%" height="200px" />;
  }

  if (!user || !user.isAuthenticated) {
    const returnUrl = encodeURIComponent(window.location.pathname);
    window.location.href = `/du/logger_inn?ReturnUrl=${returnUrl}&culture=${extractLocaleCodeFromUrl(
      window.location.href,
    )}`;
    return null;
  }

  if (isError) {
    return <NoeGikkGalt />;
  }

  // Kommer denne til å kjøre på refetch for useDUQuery? Er ikke så farlig?
  startSjekkForInaktivitetUtenDynamicImport();
  return <LoginContext.Provider value={user}>{children}</LoginContext.Provider>;
}
