import { Body, Heading, Skeleton } from '@lanekassen/ui';
import purify from 'dompurify';
import { TilleggsinfoBlokk } from '../epitekster/types';
import { useTeksterQuery } from '../hooks/queries';

interface TilleggsinfoBlokkWithLoaderProps {
  contentGuid: string;
}

function TilleggsinfoBlokkWithLoader({ contentGuid }: TilleggsinfoBlokkWithLoaderProps) {
  const { data: tilleggsinfo, isLoading } = useTeksterQuery<TilleggsinfoBlokk>(`TilleggsinfoBlock/${contentGuid}`);

  if (isLoading) {
    return <Skeleton variant="rectangular" height={40} />;
  }
  return (
    <>
      <Heading level={3} size="lg">
        {tilleggsinfo?.overskrift}
      </Heading>
      <Body
        dangerouslySetInnerHTML={{
          __html: purify.sanitize(tilleggsinfo?.tekst.value!),
        }}
      />
    </>
  );
}

export default TilleggsinfoBlokkWithLoader;
