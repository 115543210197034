import { Box, Footer, Link as LKLink } from '@lanekassen/ui';

import { useForsideteksterContext } from '../context/forsideteksterContext';

function ForeldresigneringFooter() {
  const forsideTekster = useForsideteksterContext();
  return (
    <Box sx={{ textAlign: 'center', mt: '160px' }}>
      <Footer numberOfColumns={2}>
        <LKLink
          href={forsideTekster.footerHjemmesidenLenke.href}
          title={forsideTekster.footerHjemmesidenLenke.title}
          target={forsideTekster.footerHjemmesidenLenke.target}
        >
          {forsideTekster.footerHjemmesidenLenke.text}
        </LKLink>
        <LKLink
          href={forsideTekster.footerPersonvernLenke.href}
          title={forsideTekster.footerPersonvernLenke.title}
          target={forsideTekster.footerPersonvernLenke.target}
        >
          {forsideTekster.footerPersonvernLenke.text}
        </LKLink>
      </Footer>
    </Box>
  );
}

export default ForeldresigneringFooter;
